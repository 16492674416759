var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('v-container', {
    staticClass: "error-page",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "logo-wrapper"
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/images/logo.svg"),
      "contain": "",
      "width": "62",
      "height": "48"
    }
  }), _c('span', {
    staticClass: "logo-title"
  }, [_vm._v(" Motocle")])], 1), _c('v-row', {
    staticClass: "d-flex justify-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('span', {
    staticClass: "error-logo"
  }, [_vm._v("404")]), _c('p', {
    staticClass: "error-text"
  }, [_vm._v(" Oops. Looks like the page you're looking for no longer exists ")]), _c('p', {
    staticClass: "error-subtext"
  }, [_vm._v(" But we're here to bring you back to safety ")]), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "dark": "",
      "x-large": "",
      "color": _vm.config.light.primary,
      "to": {
        name: 'Home'
      }
    }
  }, [_vm._v(" Back to Home ")])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }